import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {useNavigate} from "react-router-dom";
import  WordMark from '../../assets/WordMark.svg';
import Logo from '../../assets/Logo.svg';
import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import youtube from '../../assets/youtube.svg';
import discord from '../../assets/discord.svg';
import googlePlus from '../../assets/googlePlus.svg';


const hours = [
  { day: 'Monday - Friday', hours: '11am - 10pm' },
  { day: 'Saturday', hours: '10am - 9pm' },
  { day: 'Sunday', hours: '11am - 5pm' }];

const products = ['Magic the Gathering', 'Role Playing Games', 'Miniature Gaming', 'Board Games', 'Supplies'];

const about = ['FAQ', 'Contact Us'];

// const headTitles = ['Store Hours', 'Home', 'Products', 'Card Request', 'Calendar', 'About Us'];

const headTitles = {
  'Store Hours': 0,
  "Home": 1,
  'Products': 0,
  'Card Request': 1,
  'Calendar': 1,
  'About Us': 0
}

const handleSocialClick = (link) => {
  window.open(link, '_blank');
  console.log('Clicked:', link);
}

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const handleFooterClick = (title)  => {
    switch (title) {
      case 'Home' :
          navigate('/')
            break;

      case 'Calendar':
         navigate('/calendar')
          break;
      case 'Card Request':
         navigate('/request')
          break;
      case 'Magic the Gathering':
         navigate('/magic')
            break;
      case 'Role Playing Games':
        navigate('/rpg')
          break;
      case 'Miniature Gaming':
         navigate('/miniatures')
            break;
      case 'Board Games':
        navigate('/boardgames')
            break;
      case 'Supplies':
         navigate('/supplies')
            break;
      case 'FAQ':
         navigate('/faq')
            break;
      case 'Contact Us':
        navigate('/contact')
        break;
    }
  }

  return (
    <Box sx={{
      mx: {xs: 0, md: 12},
      position: "relative"
    }}>
      <Box sx={{
        px: {xs: 2, sm: 9, md: 12},
        bottom: 0,
        left: 0,
        width: "100%",
        mb: 3,
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`
      }}>
        <Box
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            my: 7,
            display: "flex",
            alignItems: "center",
            gap: 2
          }}>
          {/* Logo */}
          <img style={{cursor: "pointer"}} src={Logo} alt="Logo" />
          {/* WordMark */}
          <Box sx={{
            display: { xs: "none", sm: "block" }
          }}>
            <img style={{cursor: "pointer"}} src={WordMark} alt="WordMark" />
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: "space-between" }}
        >
          {Object.keys(headTitles).map((key) =>
            <List disablePadding>
              <Typography 
                variant="h2Bold" 
                onClick={() => handleFooterClick(key)}
                sx={{
                  cursor: headTitles[key] === 1 ? "pointer" : "default", 
                  color: theme.palette.bodyTextDefault.main,
                  display: key === "Store Hours" ? {xs: "none", md: "block"} : {xs: "none", md: "block"}
                }}
              >
                {key}
              </Typography>
              {key === 'Store Hours'
                ? hours.map((hour, index) =>
                  <Box sx={{
                    py:1,
                    display: {xs: "none", md: "block"}
                  }}>
                    <ListItem disablePadding>
                      <Typography variant="bodyTextReg" sx={{ color: theme.palette.bodyTextDefault.main }}>
                        {hour.day}
                      </Typography>
                      </ListItem>
                      <ListItem disablePadding>
                      <Typography variant="bodyTextReg" sx={{ color: theme.palette.bodyTextDefault.main }}>
                        {hour.hours}
                      </Typography>
                      </ListItem>
                  </Box>)
                : null
              }
              {key === 'Products'
                ? products.map((product, index) =>
                  <ListItem sx={{py:1}} disablePadding>
                    <Typography 
                      variant="bodyTextReg" 
                      onClick={() => handleFooterClick(product)}
                      sx={{
                        cursor: "pointer", 
                        color: theme.palette.bodyTextDefault.main,
                        display: {xs: "none", md: "block"}
                      }}
                    >
                      {product}
                    </Typography>
                  </ListItem>)
                : null
              }
              {key === 'About Us'
                ? about.map((about, index) =>
                  <ListItem sx={{py:1}} disablePadding>
                    <Typography
                      variant="bodyTextReg"
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.bodyTextDefault.main,
                        display: {xs: "none", md: "block"}
                      }}
                        onClick={() => handleFooterClick(about)}
                    >
                      {about}
                    </Typography>
                  </ListItem>)
                : null
              }
            </List>)}
        </Box>
        <Box sx={{
          display: "flex",
          gap: 2,
          my:6
        }}>
          <img
            style={{cursor: "pointer"}} 
            src={facebook} 
            alt="Facebook" 
            onClick={()=>(handleSocialClick('https://www.facebook.com/CollectorMania/'))} 
          />
          <img
            style={{cursor: "pointer"}} 
            src={twitter} 
            alt="Twitter" 
            onClick={()=>(handleSocialClick('https://twitter.com/CollectorMania'))}
          />
          <img
            style={{cursor: "pointer"}} 
            src={instagram} 
            alt="Instagram" 
            onClick={()=>(handleSocialClick('https://www.instagram.com/collectormania/'))}
          />
          <img
            style={{cursor: "pointer"}} 
            src={youtube} 
            alt="youtube" 
            onClick={()=>(handleSocialClick('https://www.youtube.com/channel/UCE7XRjR4VuqhxJ4GaSkb7uA'))}
          />
          <img
            style={{cursor: "pointer"}} 
            src={discord} 
            alt="Discord" 
            onClick={()=>(handleSocialClick('https://discord.gg/AeWzwtvz9V'))}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Footer;