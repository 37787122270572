import { createTheme } from "@mui/material/styles";
import "../styles/globalStyles.css"

// colors 
const colors = {
    primaryYellow: {
        100: "#FCD782",
        200: "#FCCF69",
        300: "#FBC750",
        400: "#FBBF37",
        500: "#FAB71D",
        600: "#F9B006",
        700: "#E19E05",
        800: "#C88C04",
        900: "#AF7B04",
    },
    primaryBlue: {
        100: "#7CB3DF",
        200: "#67A7DA",
        300: "#539BD5",
        400: "#3E8FD0",
        500: "#3082C4",
        600: "#2B75B0",
        700: "#26679C",
        800: "#215A87",
        900: "#1C4C73",
    },
    primaryRed: {
        100: "#E06165",
        200: "#DC4C50",
        300: "#D8373B",
        400: "#CD282D",
        500: "#B82428",
        600: "#A22023",
        700: "#8D1C1F",
        800: "#77171A",
        900: "#621315",
    },
    secondaryGreen: {
        100: "#5DD553",
        200: "#4AD03E",
        300: "#3CC430",
    },
    grayScaleDark: {
        100: "#514D43",
        200: "#433F38",
        300: "#35322C",
        400: "#272520",
        500: "#1A1815",
    },
    grayScaleLight: {
        100: "#FDFCFB",
        200: "#FCFAF8",
        300: "#FAF7F5"
    }
}

// miu theme settings
export const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: "primary"},
                    style: {
                        px: 4,
                        py: 1.25,
                        color: colors.grayScaleLight[100],
                        backgroundColor: colors.primaryBlue[500],
                        fontSize: "16pt",
                        fontWeight: 400,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: colors.primaryBlue[700],
                        }
                    }
                },
                {
                    props: {variant: "danger"},
                    style: {
                        mt:3,
                        px: 4,
                        py: 1.25,
                        color: colors.grayScaleLight[100],
                        backgroundColor: colors.primaryRed[500],
                        fontSize: "16pt",
                        fontWeight: 400,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: colors.primaryRed[700],
                        }
                    }
                },
            ]
        }
    },
    palette: {
        primary: {
            main: colors.primaryBlue[500],
        },
        secondary: {
            main: colors.secondaryGreen[300],
            error: colors.primaryRed[500],
            error2: colors.primaryRed[100]
        },
        neutral: {
            main: colors.grayScaleDark[500]
        },
        background: {
            default: colors.grayScaleLight[100]
        },
        bodyTextDefault: {
            main: colors.grayScaleDark[500]
        },
        bodyTextNegative: {
            main: colors.grayScaleLight[100]
        },
        hyperLink: {
            main: colors.primaryBlue[700],
            secondary: colors.primaryRed[700]
        },
        errorMessage: {
            main: colors.primaryRed[400],
            secondary: colors.primaryRed[300]
        }
        
    },
    typography: {
        fontFamily: "Comic Neue",
        bodyTextLight: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 300
        },
        bodyTextReg: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 400
        },
        bodyTextBold: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 700
        },
        specHeader: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 60,
            fontWeight: 700
        },
        h1Light: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 32,
            fontWeight: 300
        },
        // h1
        h1Reg: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 32,
            fontWeight: 400
        },
        h1Bold: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 32,
            fontWeight: 700
        },
        // h2
        h2Light: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 24,
            fontWeight: 300
        },
        h2Reg: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 24,
            fontWeight: 400
        },
        h2Bold: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 24,
            fontWeight: 700
        },
        // h3
        h3Light: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 18,
            fontWeight: 300
        },
        h3Reg: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 18,
            fontWeight: 400
        },
        h3Bold: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 18,
            fontWeight: 700
        },
        // h4
        h4Light: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 300
        },
        h4Reg: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 400
        },
        h4Bold: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 16,
            fontWeight: 700
        },
        buttonText: {
            fontFamily: ['Comic Neue', "cursive"].join(","),
            fontSize: 20,
            fontWeight: 700
        }
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          footerMd: 900,
          md: 1076,
          lg: 1220,
          xl: 1536,
        },
      },
})
