// Parent homepage component
import React from 'react';
import Box from '@mui/material/Box';
import GameCard from '../GamePages/GameCard';
import HomeInfoCard from './HomeInfoCard';
import MainPhotoCarousel from "./MainPhotoCarousel";
import { WordMark } from '../../assets/WordMark';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import GamesWorkshopWarhammer40kLogo1 from "../../assets/GamesWorkshopWarhammer40kLogo1.jpg";
import DNDLogo from "../../assets/DNDLogo.jpg";
import MagicTheGatheringLogo from "../../assets/MagicTheGatheringLogo.jpg";
import DnDShopImg from "../../assets/DnDShopImg.jpg";
import MTGHoldingCards from "../../assets/MTGHoldingCards.jpg";
import Warhammer40kShopImg from "../../assets/Warhammer40kShopImg.jpg";
import GoogleMapEmbed from "./GoogleMapEmbed";
import { LogoSVG } from '../../assets/Logo';

const Home = () => {
  const theme = useTheme()
  const gameObjects = [
    { image: MagicTheGatheringLogo, title: 'Magic: The Gathering' },
    { image: GamesWorkshopWarhammer40kLogo1, title: 'Miniature Gaming' },
    { image: DNDLogo, title: 'Role Playing Games' },
  ]
  const infoObjects = [
    {
      title: 'Supplies',
      description: 'We carry all the supplies for your adventures - Sleeves, dice, mats, paints, brushes, glue, and more!',
      link: "/supplies",
      ButtonName: "Supplies"
    },
    {
      title: 'Tournaments, Drafts, Events galore!',
      description: 'We regularly host play events, tournaments, drafts, and meet ups. Check out our calendar for a list of upcoming events!',
      link: "/calendar",
      ButtonName: "Calendar"
    },
    {
      title: 'Reserve your space to game',
      description: 'Game space fills up quick! Call ahead or email us today to reserve a spot for all your adventuring needs. Oh, and did we mention that we don’t charge for table space? How cool is that?!',
      link: "/contact",
      ButtonName: "Contact Us"
    }
  ];
  const staticImageObjects = [
    { image: MTGHoldingCards , text: 'Test your mettle against opponents in an epic MTG battle' },
    { image: DnDShopImg, text: 'Join in an incredible D&D campaign adventure' },
    { image: Warhammer40kShopImg, text: 'Go to war against rival armies in a Warhammer 40k match' },
  ]

  return (
    <Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"

      }}>
        <Box
          sx={{
            width: { xs: "265px", sm: "305px", footerMd: "405px", md: "505px" }

          }}>
          <WordMark width="100%" height="auto" pattern='pattern2' />
        </Box>
        <Typography sx={{ fontSize: { xs: "18pt", sm: "32pt" } }} variant='h1Reg'>
          The Ultimate Gamer's Quest
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        mt: 7,
      }}>
        <MainPhotoCarousel />
      </Box>

      <Box sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        mt: 7
      }}>
        <Typography variant='h2Reg'>
          We're a gaming and hobby store that has been serving the South Denver community for over 25 years!
        </Typography>
      </Box>

      <Box sx={{
        mx: { xs: 2, md: 9 },
        mt: 7,


      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 7, textAlign: "center" }}>
        <Typography variant='h2Reg'>
          From Magic: The Gathering, to Dungeons & Dragons, Warhammer 40K, and everything in between.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Typography variant='h2Bold'>
          We've got you covered.
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: " wrap",
        gap: 3,
        mt: 7,
      }}>
        {
          gameObjects.map((gameObj) => {
            return (
              <GameCard gameObj={gameObj} />
            )
          })
        }
      </Box>
      <Box sx={{
        mx: 9,
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            zIndex: -1,
            opacity: "7%",
            width: "1200px",
            height: "1200px",
            position: "absolute",
            Top: 50,
            right: 50,
          }}>
          <LogoSVG width={1200} height={1200} />
        </Box>
        {
          infoObjects.map((infoObj) => {
            return (
              <HomeInfoCard infoObj={infoObj} />
            )
          })
        }
      </Box>
      <Box sx={{
        mx: 9,
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{
        mt: 7,
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 3
        // flexDirection: "column"
      }}>
        {/*    this will hold the static images (maybe grid?)  */}
        {staticImageObjects.map((item) =>
          <Box sx={{
            borderRadius: "4px",
            textAlign: "center",
            width: { xs: "300px", md: "612px" }
          }}>
            <img
              src={item.image}
              style={{
                boxShadow: "0 4px 4px rgba(0, 0, 0, 25%), -8px 8px 15px rgba(53, 50, 44, 25%)"
              }}
              width="100%"
              height="auto" />
            <Box sx={{ mt: 1 }}>
              <Typography variant='h2Reg'>
                {item.text}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{
        mx: 9,
        mt: 7,
      }}>
        <hr style={{
          border: `1px solid ${theme.palette.primary.main}`
        }} />
      </Box>
      <Box sx={{
        mt: 7,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
      }}>
        <Typography variant='h2Reg'>
          So, what are you waiting for?! Head over to Collectormania and join in the fun!
        </Typography>
        <Box sx={{ my: 7, mx: 9, width: { xs: "350px", sm: "600px", footerMd: "850px", md: "980px", lg: "1248px" }, height: { xs: "350px", sm: "450px", md: "550px" } }}>
          <GoogleMapEmbed />
        </Box>
      </Box>
    </Box>
  )
}


export default Home;